
import React from "react";
import App from "./App";

const Home: React.FC = () => {
    return (
        <App />
    );
};

export default Home;

